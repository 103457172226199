<template>
  <v-container>
    <v-row>
      <div class="mt-5 px-5">
        <v-form v-model="validForm">
          <!-- Card 1: Mi Información -->
          <v-col cols="12" md="4" class="d-flex">
            <v-card
              class="pa-5 bgcards rounded-xl"
              elevation="2"
              style="width: 100%"
            >
              <v-card-title class="text-center d-flex justify-center">
                <v-icon class="primary--text" size="40px"
                  >mdi-account-outline</v-icon
                >
                <h3 class="headline mx-auto font-weight-bold">
                  <span>Mi información</span>
                </h3>
              </v-card-title>

              <v-card-text class="text-start">
                <div>
                  <pat-input
                    v-model="miInformacion.nombre"
                    label="Nombre del administrador o propietario"
                    placeholder="Delia Morales Jimenez"
                    :rules="[rules.required]"
                  />
                  <br />
                  <pat-input
                    type="date"
                    v-model="miInformacion.fechaNacimiento"
                    label="Fecha de nacimiento"
                    placeholder="DD/MM/AAAA"
                    :rules="[rules.required]"
                  />
                  <br />
                  <pat-input
                    type="email"
                    v-model="miInformacion.correo"
                    label="Correo electrónico"
                    placeholder="ejemplo@correo.com"
                    :rules="[rules.required, rules.email]"
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Card 2: Mi Negocio -->
          <v-col cols="12" md="4" class="d-flex">
            <v-card
              class="pa-5 bgcards rounded-xl"
              elevation="2"
              style="width: 100%"
            >
              <v-card-title class="text-center d-flex justify-center">
                <v-icon class="primary--text" size="40px"
                  >mdi-cart-outline</v-icon
                >
                <h3 class="headline mx-auto font-weight-bold">
                  <span>Mi negocio</span>
                </h3>
              </v-card-title>

              <v-card-text>
                <div>
                  <pat-select
                    v-model="miNegocio.giro"
                    label="Giro"
                    placeholder="Selecciona una categoría"
                    :items="categories"
                    item-text="nombre"
                    item-value="id"
                    :rules="[rules.required]"
                  />
                  <br />
                  <span class="textlabel--text font-weight-bold"
                    >¿Qué es esto?</span
                  >
                  <v-radio-group
                    v-model="miNegocio.businessCategory"
                    class="mb-1"
                  >
                    <v-radio
                      label="Sucursal (Hay venta al público)"
                      value="venta"
                      color="purple"
                    />
                    <v-radio
                      label="Almacén (Solo se almacena producto)"
                      value="almacen"
                      color="purple"
                    />
                  </v-radio-group>
                  <br />
                  <pat-input
                    v-model="miNegocio.nombre"
                    label="Nombre de mi negocio"
                    placeholder="Farmacia la Divina"
                    :rules="[rules.required]"
                  />
                  <br />
                  <pat-input
                    v-model="miNegocio.direccion"
                    label="Dirección"
                    placeholder="Tabasco 134"
                    :rules="[rules.required]"
                  />
                  <br />
                  <pat-input
                    label="Teléfono"
                    placeholder="55 5555 5555"
                    v-model="miNegocio.PhoneNumber"
                    :maxlength="10"
                    :isNumber="true"
                    :rules="[rules.required]"
                  />
                  <br />
                  <span class="textlabel--text"
                    >¿Estos datos corresponden a una de tus sucursales?</span
                  >
                  <v-radio-group
                    v-model="miNegocio.sucursales"
                    :rules="[rules.required]"
                  >
                    <v-radio label="Sí" value="true" />
                    <v-radio label="No" value="false" />
                  </v-radio-group>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Mensaje de error -->
          <v-col cols="12" class="d-flex justify-center" v-if="!validForm">
            <div class="d-flex align-center red lighten-5 pa-2 rounded-xl mx-5">
              <v-icon color="red" class="mr-2">mdi-alert-circle</v-icon>
              <span class="red--text"
                >Completa los campos obligatorios para continuar</span
              >
            </div>
          </v-col>

          <!-- Botón Continuar -->
          <v-col cols="12" md="4" class="d-flex justify-center px-8">
            <v-btn
              :disabled="!validForm"
              class="py-6"
              :color="validForm ? 'primary' : 'grey darken-2'"
              block
              rounded
              @click="redirectToHome"
            >
              Continuar
            </v-btn>
          </v-col>
        </v-form>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { saveUserDetails, saveBusinessDetails } from "../services/auth";
import { createSucursal } from "../services/auth";

export default {
  name: "create-account",
  data() {
    return {
      validForm: false,
      categories: [
        { id: 1, nombre: "Farmacia" },
        { id: 2, nombre: "Abarrotes" },
      ], // Ejemplo de categorías
      miInformacion: {
        nombre: "",
        fechaNacimiento: "",
        correo: "",
      },
      miNegocio: {
        giro: "",
        businessCategory: "",
        nombre: "",
        direccion: "",
        PhoneNumber: "",
        sucursales: null,
      },
      rules: {
        required: (value) => !!value || "Este campo es requerido.",
        email: (value) =>
          /.+@.+\..+/.test(value) || "Ingresa un correo electrónico válido",
      },
    };
  },
  methods: {
    async redirectToHome() {
      if (this.validForm) {
        const responses = await Promise.all([
          saveUserDetails(
            this.miInformacion.nombre,
            this.miInformacion.fechaNacimiento,
            this.miInformacion.correo
          ),
          saveBusinessDetails(
            this.miNegocio.giro,
            this.miNegocio.nombre,
            this.miNegocio.direccion
          ),
        ]);
        if (responses[0].success && responses[1].success) {
          if (this.miNegocio.sucursales === "true") {
            this.createBranch();
            this.$router.push({ name: "home" });
          } else {
            this.$router.push({ name: "create-branch" });
          }
        } else {
          this.$refs.toast.show({
            message: "Ocurrió un error al guardar tus datos",
            color: "error",
          });
        }
      }
    },
    async createBranch() {
      const response = await createSucursal(
        this.miNegocio.nombre,
        this.miNegocio.direccion,
        this.miNegocio.PhoneNumber,
        this.miNegocio.businessCategory
      );
      if (response.success) {
        console.log("sucursal creada:" + this.miNegocio);
        this.$toast({
          message: "Sucursal creada correctamente",
          color: "success",
          timeout: 2000,
        });
        this.$router.push({ name: "branches" });
      } else {
        this.$toast({
          message: "Ocurrió un error al crear la sucursal",
          color: "error",
          timeout: 2000,
        });
      }
      console.log("Sucursal creada:", response);
    },
  },
};
</script>

<style></style>
